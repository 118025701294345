import { getColor } from '@/utils/theme/colors';
import StarSvg from '@/assets/ProductBadges/Star.svg';
import BellSvg from '@/assets/ProductBadges/Bell.svg';
import NewArrivalsSvg from '@/assets/ProductBadges/NewArrivals.svg';
import NotAllowedSvg from '@/assets/ProductBadges/NotAllowed.svg';
import PriceDropSvg from '@/assets/ProductBadges/PriceDrop.svg';
import QuickShipSvg from '@/assets/ProductBadges/QuickShip.svg';

export const BadgeContentAndStyleMapper = new Map([
  [
    'price-drop',
    {
      content: {},
      Icon: PriceDropSvg,
      style: {
        container: {
          background: 'linear-gradient(180deg, #FFE878 0%, #FFDD4B 100%)',
        },
        content: {
          color: getColor('Yellow +4'),
        },
        badgeFold: {
          backgroundColor: getColor('Yellow +4'),
        },
      },
    },
  ],
  [
    'limited-stocks',
    {
      content: {},
      Icon: BellSvg,
      style: {
        container: {
          background: 'linear-gradient(180deg, #FAD27A 0%, #F4BF59 100%)',
        },
        content: {
          color: getColor('Warning +2'),
        },
        badgeFold: {
          backgroundColor: getColor('Warning +2'),
        },
      },
    },
  ],
  [
    'quick-ship',
    {
      content: {},
      Icon: QuickShipSvg,
      style: {
        container: {
          background: ' linear-gradient(180deg, #94ECFF 0%, #79DFFF 100%)',
        },
        content: {
          color: getColor('Blue +4'),
        },
        badgeFold: {
          backgroundColor: getColor('Blue +4'),
        },
      },
    },
  ],
  [
    'new-arrivals',
    {
      content: {},
      Icon: NewArrivalsSvg,
      style: {
        container: {
          background: ' linear-gradient(180deg, #9FF087 0%, #79E168 100%)',
        },
        content: {
          color: getColor('Success +2'),
        },
        badgeFold: {
          backgroundColor: getColor('Success +2'),
        },
      },
    },
  ],
  [
    'best-seller',
    {
      content: {},
      Icon: StarSvg,
      style: {
        container: {
          background: 'linear-gradient(180deg, #C1F0F4 0%, #68E0ED 100%)',
        },
        content: {
          color: getColor('Minty +3'),
        },
        badgeFold: {
          backgroundColor: getColor('Minty +1'),
        },
      },
    },
  ],
  [
    'pride-month',
    {
      content: {},
      Icon: StarSvg,
      style: {
        container: {
          background: 'linear-gradient(180deg, #C1F0F4 0%, #68E0ED 100%)',
        },
        content: {
          color: getColor('Minty +3'),
        },
        badgeFold: {
          backgroundColor: getColor('Minty +1'),
        },
      },
    },
  ],
  [
    'out-of-stock',
    {
      content: {},
      Icon: NotAllowedSvg,
      style: {
        container: {
          background: 'linear-gradient(180deg, #BCBCBC 0%, #999 100%)',
        },
        content: {
          color: getColor(''),
        },
        badgeFold: {
          backgroundColor: getColor('Gray 0'),
        },
      },
    },
  ],
]);