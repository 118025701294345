import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box, BoxProps, Button, SxProps } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import SomethingWentWong from '@/assets/errors/FlyingManErrorState.svg';
import { useEffect } from 'react';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '../NotifyToast';

function GenericErrorScreen(props: {
	image?: any;
	title?: string;
	subtitle?: string;
	wrapperProps?: BoxProps;
	// children?: React.ReactNode;
	children?: React.ReactNode;
	sx?: SxProps<any>;
	onRefresh?:any
	refreshText?:any
}) {
	const { actions } = useNotification();
	useEffect(() => {
		// actions.notifyUser({
		// 	severity: NotificationSeverity.ERROR,
		// });
		// It repeats two times, need a fix
	}, []);

	return (
		<Box
			sx={{ textAlign: 'center', margin: '200px 0' }}
			{...props.wrapperProps}
		>
			<Box sx={{ width: '100%', margin: '48px 0' }}>
				{props.image ? <props.image /> : <SomethingWentWong />}
			</Box>
			<Box sx={{ margin: '30px 0' }}>
				<LencoTypography
					sx={{
						...getStyle('H14_Medium'),
						color: getColor('Gray 0'),
						margin: '12px 0',
					}}
				>
					{props.title || 'Something Went Wrong'}
				</LencoTypography>
				<LencoTypography
					sx={{ ...getStyle('P_Medium'), color: getColor('Gray -2') }}
				>
					{props.subtitle || 'Please try again after sometime'}
				</LencoTypography>
			</Box>
			<Box>
				<Button variant='outlined' onClick={() => {props?.onRefresh ? props?.onRefresh() : window?.location?.reload()}}>
					{props?.refreshText ? props?.refreshText : 'Refresh'}
				</Button>
			</Box>
		</Box>
	);
}

export default GenericErrorScreen;
