import FlexBox from '@/atomic-components/FlexBox';
import { getColor } from '@/utils/theme/colors';
import { Plan } from '@/store/unlmtd/models/plan';
import usePlan from '@/hooks/unlmtd/usePlan';
import { getStyle } from '@/utils/theme/fonts';
import { Box, Typography, Button, useTheme, colors } from '@mui/material';
// import { SubscriptionPlanDetail } from './PlanData';
import { useLocation } from '@/hooks/useLocation';
import { PlanSelectorStatus } from '@/store/unlmtd/slices/planConfigSlice';
import { useRouter } from 'next/router';
import { useCart } from '@/hooks/unlmtd/useCart';
import { useNotification } from '@/hooks/useNotification';
import { NotificationSeverity } from '@/components/NotifyToast';
import { dataLayerPushWithEvent } from '@/utils/callcustomevent';
import { useState } from 'react';

const shouldRedirect = ['/unlmtd']

export default function PlanSelectorTile({
	data,
	isSelected,
	source,
}: {
	data: Plan;
	isSelected: boolean;
	source: string;
}) {
	const theme = useTheme();
	const { selectedLocation } = useLocation();
	const [isFetching, setFetching] = useState(false);
	const { selectedPlanId, planActions } = usePlan();
	const router = useRouter();
	const { cart, cartActions, totalCartItems } = useCart();
	const { actions } = useNotification();
	const onSelectPlan = async () => {
        setFetching(true)

		try{
			planActions.setPlan(String(data?.id));
		planActions.showOrHidePlanSelector(PlanSelectorStatus.PLAN_HIDE);
		dataLayerPushWithEvent('UNLMTD Plan Selected', {
			city: selectedLocation?.cityName,
			plan:data?.label,
			plan_value: data?.salePrice?.value,
		})
		await cartActions.fetchCart();
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'UNLMTD Plan Selected',
			moe_event_data: {
				plan: data?.label,
				plan_value: data?.salePrice?.displayValue,
				city: selectedLocation?.cityName,
				position: source,
				plan_state: selectedPlanId
					? 'select plan'
					: totalCartItems == data?.totalProductCount
					? 'view cart'
					: 'browse catalog',
			},
		});
		actions.notifyUser({
			message: 'Plan Selected',
			severity: NotificationSeverity.INFO,
		});
		}
		finally{
            setFetching(false)
            if(shouldRedirect?.includes(window?.location?.pathname)){
            router?.push(`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`)

            }
            // cart?.cart?.data?.totalCartItems == data?.totalProductCount ? router?.push('/unlmtd/cart') :router?.push(`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`)
       }
		
		
	};

	return (
		<FlexBox
			direction='column'
			gap='15px'
			sx={{
				borderRadius: '8px',
				padding: '20px 24px',
				background: isSelected
					? 'linear-gradient(180deg, #39315C 0%, #6653A4 100%)'
					: data?.stateConfig?.webBackgroundColors,
				width: 'max-content',
				minWidth: '230px',
				transition: 'background 3s ease-in-out, transform 0.3s ease-in-out',
				':hover': {
					background: 'linear-gradient(180deg, #6653A4 0%, #39315C 100%)',
				},
			}}
		>
			<FlexBox
				justifyContent='start'
				alignItems='center'
				gap='8px'
				style={{ justifyContent: 'start', width: '100%' }}
			>
				<Typography
					sx={{
						color: theme?.colors?.lencoSecondaryN4,
						...getStyle('H2_SemiBold'),
						// lineHeight: '38px',
					}}
				>
					{data?.totalProductCount}
				</Typography>
				<Box>
					<Typography
						sx={{
							...getStyle('H11_Regular'),
							color: getColor(''),
							// lineHeight: '20px',
						}}
					>
						Items
					</Typography>
					<FlexBox>
						<Typography
							sx={{
								...getStyle('Small_SemiBold'),
								color: getColor(''),
								// lineHeight: '12px',
							}}
						>
							₹ {Math.floor(data?.salePrice?.value / data?.totalProductCount)}
						</Typography>
						&nbsp;
						<Typography
							sx={{
								...getStyle('Small_Regular'),
								color: getColor(''),
								// lineHeight: '12px',
							}}
						>
							per product
						</Typography>
					</FlexBox>
				</Box>
			</FlexBox>
			<FlexBox
				direction='column'
				gap='8px'
				style={{ justifyContent: 'start', alignItems: 'start' }}
			>
				<FlexBox style={{ justifyContent: 'start' }}>
					<Typography
						sx={{
							color: getColor(''),
							...getStyle('H6_SemiBold'),
							// lineHeight: '24px',
						}}
					>
						{data?.salePrice?.displayValue}
					</Typography>
					{/* <Typography
						sx={{
							color: theme?.colors?.lencoPrimaryN3,
							...getStyle('H6_SemiBold'),
							// lineHeight: '24px',
						}}
					>
						/mo
					</Typography> */}
				</FlexBox>
				<Typography
					sx={{
						textDecoration: 'line-through',
						textDecorationColor: getColor(''),
						...getStyle('Small_Regular'),
						color: getColor(''),
						// lineHeight: '12px',
					}}
				>
					{data?.strikePrice?.displayValue}{' '}
				</Typography>
			</FlexBox>
			<FlexBox
				direction='column'
				gap='6px'
				style={{ justifyContent: 'start', alignItems: 'start', width: '100%' }}
			>
				<Typography
					sx={{
						fontSize: '24px',
						fontWeight: '700',
						background: 'linear-gradient(90deg, #C072AC 0%, #DEB6D4 100%)',
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
						lineHeight: '30px',
					}}
				>
					{data?.label}
				</Typography>{' '}
				<Typography
					sx={{
						...getStyle('Small_Regular'),
						color: theme?.colors?.lencoSecondaryN5,
						lineHeight: '12px',
					}}
				>
					{data?.stateConfig?.subText?.text}
				</Typography>
			</FlexBox>
			<Button
				sx={{
					backgroundColor: isSelected
						? theme?.colors?.lencoSecondaryP2
						: theme?.colors?.lencoSecondaryP1,
					color: getColor('gray-5'),
					maxHeight: '35px',
					...getStyle('Small-Regular'),
					lineHeight: '12px',
					borderRadius: '8px',
					border:isSelected?'1px solid #D49FC7':'none',
					':hover': {
						background: theme?.colors?.lencoSecondaryP1,
					},
				}}
				onClick={() => {
					isSelected
						? totalCartItems == data?.totalProductCount? router?.push('/unlmtd/cart') : router?.push(
								`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`
						  )
						: onSelectPlan();
				}}
			>
				{isFetching?
				<Box sx={{}}>
				<div className='circular-loader-unlmtd'></div>
				</Box> : isSelected ? totalCartItems == data?.totalProductCount ? 'View Cart' :'Browse Catalog' : 'Select Plan' 	}
				{/* {`/unlmtd/${selectedLocation?.cityName}/bedroom-unlmtd?collectionType=CATEGORY_UNLMTD`} */}
				
			</Button>
		</FlexBox>
	);
}
