import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import { ICarousel } from './model';
import { EffectCoverflow, Lazy, Navigation, Pagination } from 'swiper';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getColor } from '@/utils/theme/colors';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import SwiperButton from '@/assets/swiper/LencoSwiperButtons.svg';
import SwiperButtonHoverable from '@/assets/swiper/LencoSwiperButtonHover.svg';

import Hoverable from '@/atomic-components/Hoverable';

function Carousel(props: ICarousel) {
	const swiperRef = React.useRef<SwiperRef>(null);
	const [currIndex, setCurrIndex] = useState<number>(0);
	return (
		<>
			{swiperRef &&
				Array.isArray(props.children) &&
				props.children?.length > props?.slidesPerView && (
					<Box
						onClick={() =>
							props.children!.length > currIndex &&
							swiperRef?.current?.swiper?.slidePrev()
						}
						sx={{
							transform: 'rotate(180deg)',
							position: 'absolute',
							zIndex: 9,
							top: '35%',
							left: '-25px',
							tranform: 'translate(-50%)',
							cursor: 'pointer',
						}}
					>
						<Hoverable
							onClick={() => {}}
							icons={{
								before: <SwiperButton />,
								after: <SwiperButtonHoverable />,
								clicked: <SwiperButtonHoverable />,
							}}
						/>
					</Box>
				)}

			<Swiper
				ref={swiperRef}
				modules={[Navigation, Pagination]}
				className='LencoSwiper'
				loop={props?.loop || false}
				spaceBetween={props.spaceBetween}
				initialSlide={0}
				slidesPerView={props.slidesPerView}
				pagination={props.dots}
				centeredSlides={props?.centered}
				onSlideChange={(e) => {
					setCurrIndex(e.activeIndex);
				}}
			>
				{props?.children?.map((d) => {
					return <SwiperSlide key={d.key}>{d}</SwiperSlide>;
				})}
			</Swiper>
			{swiperRef &&
				Array.isArray(props.children) &&
				props.children?.length > props?.slidesPerView && (
					<Box
						onClick={() =>
							props.children!.length > currIndex &&
							swiperRef?.current?.swiper?.slideNext()
						}
						id='nextButton'
						sx={{
							position: 'absolute',
							zIndex: 9,
							top: '35%',
							right: '-15px',
							cursor: 'pointer',
						}}
					>
						<Hoverable
							onClick={() => {}}
							icons={{
								before: <SwiperButton />,
								after: <SwiperButtonHoverable />,
								clicked: <SwiperButtonHoverable />,
							}}
						/>
					</Box>
				)}
		</>
	);
}

export default Carousel;
