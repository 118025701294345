import FlexBox from '@/atomic-components/FlexBox';
import { LencoImage } from '@/atomic-components/Image';
import { getColor } from '@/utils/theme/colors';
import { getStyle } from '@/utils/theme/fonts';
import { Box, Typography } from '@mui/material';
import { BadgeInfo } from '../search-results/model';
import { BadgeContentAndStyleMapper } from './badges';
import useBadgeData from '@/hooks/useBadge';

const getBadgeContainerStyle = (badgeName: string) => {
	const badge = BadgeContentAndStyleMapper.get(badgeName);
	return badge?.style.container;
};

const getBadgeContentStyle = (badgeName: string) => {
	const badge = BadgeContentAndStyleMapper.get(badgeName);
	return badge?.style?.content ?? {};
};

const getBadgeIcon = (badgeName: string) => {
	const badge = BadgeContentAndStyleMapper.get(badgeName);

	if (!badge) return;

	return badge?.Icon;
};

const getBadgeFoldColor = (badgeName: string) => {
	const badge = BadgeContentAndStyleMapper.get(badgeName);

	return badge?.style?.badgeFold?.backgroundColor;
};

interface ProductBadgeComponentProps {
	badge: BadgeInfo;
	badgesInfo?: any;
}

const ProductBadgeComponent = (props: ProductBadgeComponentProps) => {
	const { badge,badgesInfo } = props;
	// if(!badgesInfo[badge?.name]) return <></>
	const badgeData=badgesInfo?.data?.badge[badge.name]
	if(!badgeData) return <></>
	
	return (
		<Box>
			<FlexBox
				alignItems='center'
				sx={{
					justifyContent: 'start',
					width: 'fit-content',
					padding: '4px 16px',
					borderRadius: '0px 16px 16px 0px',
					background:`linear-gradient(180deg, ${badgeData?.gradientStartColor || 'white'} 0%, ${badgeData?.gradientEndColor || 'white'} 100%)`,
				}}
			>
				<Box sx={{ marginRight: '4px', width: '10px', height: '10px' }}>
					{badgeData && (
					<LencoImage
						lazy={false}
						url={badgeData?.iconUrl}
						aspectRatio={1}
					/>
				)}		
				</Box>
				<Typography
					ml='8px'
					sx={{
						lineHeight: 1,
						...getStyle('Tiny_Medium'),
						color:badge?.name == 'out-of-stock' ? getColor('') : badgeData?.textColor,
					}}
				>
					{badge.label}
				</Typography>
			</FlexBox>
			<Box mt='-6px'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='8'
					height='8'
					viewBox='0 0 8 8'
					fill='none'
				>
					<path d='M0 0H8V8L0 0Z' fill={badgeData?.textColor} />
				</svg>
			</Box>
		</Box>
	);
};

export default ProductBadgeComponent;
