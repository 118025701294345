import { LencoTypography } from "@/atomic-components/LencoTypography";
import { getStyle } from "@/utils/theme/fonts";
import { Box } from "@mui/material";

function HtmlContentDiv(props:any) {
    return (
        <LencoTypography
        componentUsage='PARAGRAPH'
        dangerouslySetInnerHTML={{
            __html: props?.data,
        }}>
        </LencoTypography>
      );
}

export default HtmlContentDiv;