import { getColor } from '@/utils/theme/colors';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Carousel from '..';
import { ICarousel } from '../model';

const CustomDiv = styled(Box)`
max-width:1440px;
width:100%;
position:relative;
.LencoSwiper .swiper{
    position:relative;

}
.LencoSwiper .swiper-pagination {
    text-align:left;
}
.swiper-wrapper{
    align-items:center;
}
.LencoSwiper .swiper-wrapper{
    
}

.LencoSwiper .swiper-slide{
    border-radius:8px;
    overflow:hidden;

}

.LencoSwiper .swiper-button-prev {
    background-color: red;
    padding: 8px 16px;
    border-radius: 100%;
    border: 2px solid black;
    color: red;
  }

.LencoSwiper .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: ${getColor('Gray 0')};
    opacity: .1;
    -webkit-border-radius: 20px;
            border-radius: 20px;
    -webkit-transition: opacity .5s, background-color .5s, width .5s;
    -o-transition: opacity .5s, background-color .5s, width .5s;
    transition: opacity .5s, background-color .5s, width .5s;
    -webkit-transition-delay: .5s, .5s, 0s;
         -o-transition-delay: .5s, .5s, 0s;
            transition-delay: .5s, .5s, 0s;
    }        
}
.LencoSwiper .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}
.LencoSwiper .swiper-pagination .swiper-pagination-bullet-active {
    opacity:0.3;
    background: ${getColor('Gray 0')};
    width: 30px;
    -webkit-transition-delay: 0s;
         -o-transition-delay: 0s;
            transition-delay: 0s;
}  
`;

export const BasicCarousel = (
	props: ICarousel & { isLeftAligned?: boolean }
) => {
	return (
		<CustomDiv>
			<Carousel {...props} />
		</CustomDiv>
	);
};
