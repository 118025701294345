export const dataLayerPushWithEvent = (eventName: string, data: any) => {
  try {
    (window as any).dataLayer && (window as any).dataLayer.push({
      event: eventName,
      ...data
    });
  } catch (error) {
    console.log('GTM is disabled or not loaded');
  }

};

export const dataLayerPush = (data: any) => {
  try {
    (window as any).dataLayer && (window as any).dataLayer.push({
      ...data
    });
  } catch (error) {
    console.log('GTM is disabled or not loaded');
  }
};