
import BaseService from '@/services/base-service';
import { GetLayoutApiResponse } from './models/layout';

class LayoutService extends BaseService {
  constructor() {
    super({
      serviceName: 'Layout Service'
    });
  }

  public getLayout(layoutType: string): Promise<GetLayoutApiResponse> {
    return this.client.get(`/layout?type=${layoutType}`);
  }

  public getFallbackLayout(layoutType: string): Promise<GetLayoutApiResponse> {
    return this.client.get(`/layout/fallback/${layoutType}`);
  }

  public getHomeLayout(): Promise<any> {
    return this.client.get('layout?type=HOME_WEB');
  }
  public getRentLayout(): Promise<any> {
    return this.client.get('/layout?type=RENT_WEB');
  }
  public getBuyLayout(): Promise<any> {
    return this.client.get('/layout?type=BUY_WEB');
  }

  public getSeoContent(cityName: string): Promise<any> {
    return this.client.get(`/layout/key/${cityName}`);
  }

  public getKeys(key_name:string): Promise<any> {
    return this.client.get(`/layout/key/${key_name}`);
  }

  public getProductBadges(): Promise<any> {
    return this.client.get(`/layout/key/Badge_V1`);
  }

}

export const layoutService = new LayoutService();
